// store.js
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    localurl: Vue.prototype.$localurl,
    reemplazos: null,
    reemplazante: null,
    tools: { initial: [], selected: {} },
    roles: { initial: [], selected: {} },
    usersCount: 0
  },
  getters: {
    reemplazos(state) {
      return state.reemplazos;
    },
    reemplazante(state) {
      return state.reemplazante;
    },
    getTools(state) {
      return state.tools;
    },
    getRoles(state) {
      return state.roles;
    },
    getUsersCount(state) {
      return state.usersCount;
    }
  },
  mutations: {
    setReemplazos(state, reemplazos) {
      state.reemplazos = reemplazos;
    },
    setReemplazante(state, reemplazante) {
      state.reemplazante = reemplazante;
    },
    SET_TOOLS(state, tools) {
      state.tools.initial = tools;
    },
    SET_ROLES(state, roles) {
      state.roles.initial = roles;
    },
    UPDATE_SELECTED_TOOLS(state, { app, isSelected }) {
      state.tools.selected[app] = isSelected;
    },
    UPDATE_SELECTED_ROLES(state, { role, isSelected }) {
      state.roles.selected[role] = isSelected;
    },
    SET_USERS_COUNT(state, count) {
      // Nueva mutación
      state.usersCount = count;
    },
    RESET_STATE(state) {
      state.tools = {
        ...state.tools,
        selected: {}
      };
      state.roles = {
        ...state.roles,
        selected: {}
      };
      state.usersCount = 0;
    }
  },
  actions: {
    async fetchTools({ commit }, axiosInstance) {
      try {
        const response = await axiosInstance.get(`/api/users/tools`);
        commit("SET_TOOLS", response.data);
      } catch (error) {
        console.error("Error fetching tools:", error);
      }
    },
    async fetchRoles({ commit }, axiosInstance) {
      try {
        const response = await axiosInstance.get(`/api/users/internal-roles`);
        commit("SET_ROLES", response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    async getUsersCount({ state, commit }, axiosInstance) {
      try {
        const body = {
          applications: Object.keys(state.tools.selected).filter(
            key => state.tools.selected[key]
          ),
          internalRoles: Object.keys(state.roles.selected).filter(
            key => state.roles.selected[key]
          )
        };
        const response = await axiosInstance.post(`/api/users/count`, body);
        commit("SET_USERS_COUNT", response.data.usersCount);
      } catch (error) {
        console.error("Error obtaining user count:", error);
      }
    },
    initializeSelectedTools({ commit, state }) {
      state.tools.initial.forEach(tool => {
        commit("UPDATE_SELECTED_TOOLS", { app: tool.app, isSelected: false });
      });
    },
    initializeSelectedRoles({ commit, state }) {
      state.roles.initial.forEach(role => {
        commit("UPDATE_SELECTED_ROLES", { role: role.role, isSelected: false });
      });
    },
    updateSelectedTools(
      { commit, dispatch, state },
      { app, isSelected, axiosProp }
    ) {
      commit("UPDATE_SELECTED_TOOLS", { app, isSelected });

      // Si no hay ningún tipo de usuario seleccionado, seleccionar todos
      const noRolesSelected = Object.values(state.roles.selected).every(
        selected => !selected
      );
      if (noRolesSelected) {
        state.roles.initial.forEach(role => {
          commit("UPDATE_SELECTED_ROLES", {
            role: role.role,
            isSelected: true
          });
        });
      }

      // Llamar a getUsersCount para actualizar el conteo
      dispatch("getUsersCount", axiosProp);
    },
    updateSelectedRoles(
      { commit, dispatch, state },
      { role, isSelected, axiosProp }
    ) {
      commit("UPDATE_SELECTED_ROLES", { role, isSelected });

      // Si no hay ninguna herramienta seleccionada, seleccionar todas
      const noToolsSelected = Object.values(state.tools.selected).every(
        selected => !selected
      );
      if (noToolsSelected) {
        state.tools.initial.forEach(tool => {
          commit("UPDATE_SELECTED_TOOLS", { app: tool.app, isSelected: true });
        });
      }

      // Llamar a getUsersCount para actualizar el conteo
      dispatch("getUsersCount", axiosProp);
    },
    resetState({ commit }) {
      console.log("resetState");
      commit("RESET_STATE");
    }
  },
  modules: {}
});

store.subscribe((mutation, state) => {
  localStorage.setItem("store", JSON.stringify(state));
});

export default store;
