<template>
  <p :class="['text', get_classes]">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: {
    light: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    extra_large: Boolean,
  },
  computed: {
    get_classes() {
      console.log("si");
      
      return {
        "text--light": this.light,
        "text__small": this.small,
        "text__medium": this.medium,
        "text--large": this.large,
        "text--extra-large": this.extra_large,
      };
    },
  }
};
</script>

<style scoped src="@/assets/css/components/form/text.scss" lang="scss"></style>
