<template>
  <button
    @click="$emit('click', $event)"
    :disabled="disabled"
    :class="['button', get_classes]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    gray: Boolean,
    inline: Boolean,
    light: Boolean,
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    wide: Boolean,
    disabled: Boolean,
    error: Boolean,
    success: Boolean,
    mobile_full_width: Boolean,
    type: {
      type: String,
      default: "default"
    },
    state: {
      type: String,
      default: "none"
    }
  },
  computed: {
    get_classes() {
      return {
        "button--gray": this.gray,
        "button--inline": this.inline,
        "button--light": this.light,
        "button__small": this.small,
        "button__medium": this.medium,
        "button--large": this.large,
        "button--wide": this.wide,
        "button--disabled": this.disabled || this.is_disabled,
        "button--error": this.error,
        "button--success": this.success || this.is_sent,
        "button--mobile-full-width": this.mobile_full_width,
      };
    },
    is_sending() {
      return this.state === "sending";
    },
    is_wait() {
      return this.state === "wait";
    },
    is_sent() {
      return this.state === "sent";
    },
    is_disabled() {
      return true && (this.is_sending || this.is_wait);
    }
  }
};
</script>

<style scoped src="@/assets/css/components/form/button.scss" lang="scss"></style>
