<template>
  <div class="login">
    <div class="login-container">
      <template v-if="!showSuccess">
        <div class="login-row">
          <label class="login-label">Nueva contraseña</label>
          <input
            class="login-input"
            type="password"
            ref="loginPassword1"
            v-on:keyup.enter="handleLogin()"
            v-bind:class="{ 'error-input': errorPassword }"
          />
          <img
            class="login-input-ojo"
            src="../assets/img/ojo.png"
            v-show="!mostrarOjoActivo"
            @click="changePasswordType('text')"
          />
          <img
            class="login-input-ojo"
            src="../assets/img/ojo-active.png"
            v-show="mostrarOjoActivo"
            @click="changePasswordType('password')"
          />
        </div>
        <div class="login-row">
          <label class="login-label">Repetir contraseña</label>
          <input
            class="login-input"
            type="password"
            ref="loginPassword2"
            v-on:keyup.enter="handleLogin()"
            v-bind:class="{ 'error-input': errorPassword }"
          />
          <img
            class="login-input-ojo"
            src="../assets/img/ojo.png"
            v-show="!mostrarOjoActivo"
            @click="changePasswordType('text')"
          />
          <img
            class="login-input-ojo"
            src="../assets/img/ojo-active.png"
            v-show="mostrarOjoActivo"
            @click="changePasswordType('password')"
          />
        </div>
        <button class="login-btn" @click="handleLogin()">Ingresar</button>
      </template>
      <template v-else>
        <div class="login__change-password--success">
          <span class="icon material-symbols-outlined">
            check_circle
          </span>
          <TextDisplay extra_large light>Tu contraseña se modificó correctamente</TextDisplay>
          <Button light large inline @click="goToLogin()">Volver al login</Button>
        </div>
      </template>
    </div>
    <div class="login-right">
      <div class="login-right-mask"></div>
      <div class="login-logo-container">
        <img class="login-logo" src="../assets/img/logo.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/form/Button.vue";
import TextDisplay from "@/components/form/TextDisplay.vue";

export default {
  name: "login",
  components: { Button, TextDisplay },
  data() {
    return {
      mostrarOjoActivo: false,
      errorPassword: false,
      showSuccess: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    changePasswordType(tipo) {
      if (tipo == "text") {
        this.mostrarOjoActivo = true;
      } else {
        this.mostrarOjoActivo = false;
      }
      this.$refs.loginPassword1.type = tipo;
      this.$refs.loginPassword2.type = tipo;
    },
    async handleLogin() {
      let guardar = true;

      let password1 = this.$refs.loginPassword1.value;
      let password2 = this.$refs.loginPassword2.value;

      if (
        password1 == "" ||
        password2 == "" ||
        password1 != password2 ||
        !this.chequearPassword(password1)
      ) {
        guardar = false;
        this.errorPassword = true;
      } else {
        this.errorPassword = false;
      }
      if (guardar == true) {
        let json = {
          password1: password1,
          password2: password2,
        };
        try {
          await this.$axios.post(
            this.$localurl + "/api/usuario/cambiarPassword/" + this.$route.params.url,
            json
          );
          this.showSuccess = true;
        } catch (error) {
          this.$eventHub.$emit("snackbar", "URL incorrecta");
          this.errorPassword = true;
        }
      }
    },
    goToLogin(){
      this.$router.push("/login");
    },
    chequearPassword(password1) {
      let format = new RegExp(/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?.]/);
      let mensaje = "";
      let retorno = true;
      if (password1.length < 8) {
        mensaje = "Mínimo 8 caracteres";
        retorno = false;
      }
      if (password1.replace(/[^A-Za-z]+/g, "").length == 0) {
        mensaje =
          mensaje == "" ? "Debe tener letras" : mensaje + ", debe tener letras";
        retorno = false;
      }
      if (password1.replace(/\D/g, "").length == 0) {
        mensaje =
          mensaje == ""
            ? "Debe tener números"
            : mensaje + ", debe tener números";
        retorno = false;
      }
      if (format.test(password1) == false) {
        mensaje =
          mensaje == ""
            ? "Debe tener un caracter especial " + this.caracteres()
            : mensaje +
              ", debe tener un caracter especial " +
              this.caracteres();
        retorno = false;
      }
      if(mensaje !== ""){
        this.$eventHub.$emit("snackbar", mensaje);
      }
      return retorno;
    },
    caracteres() {
      return "([]~`!#$%^&*+=\\-';,./{}|" + '":<>?)';
    },
  },
};
</script>

<style>
@import "../assets/css/views/login.css";
</style>
