<template>
  <div ref="dropdown" class="dropdown" @click="toggleDropdown">
    <slot name="button"></slot>
    <transition name="fade">
      <div class="dropdown-content" :class="{ hidden: !isOpen }" @click.stop>
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DropdownV2",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$emit("toggle", this.isOpen); // Comunica el estado al padre
    },
    closeDropdown() {
      this.isOpen = false;
      this.$emit("toggle", this.isOpen); // Comunica el estado cerrado
    },
    handleOutsideClick(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  overflow-wrap: break-word;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hidden {
  display: none;
}
</style>
