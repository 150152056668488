import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AxiosClient from "./axiosClient";
import AuthService from "./services/auth.service";
import clickOutside from './directives/click.outside';
import infocus from './directives/infocus';


if (process.env.VUE_APP_MODE == "prod") {
  Vue.prototype.$localurl = process.env.VUE_APP_URL;
  Vue.prototype.$cyo_url = process.env.VUE_APP_CYO_URL;
  Vue.prototype.$cc_url = process.env.VUE_APP_CC_URL;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL;
  Vue.prototype.$mialta_url = process.env.VUE_APP_MIALTA_URL;
  Vue.prototype.$mispedidos_url = process.env.VUE_APP_MISPEDIDOS_URL;
  Vue.prototype.$dev = false;
} else if (process.env.VUE_APP_MODE == "dev") {
  Vue.prototype.$localurl = process.env.VUE_APP_URL_DEV;
  Vue.prototype.$cyo_url = process.env.VUE_APP_CYO_URL_DEV;
  Vue.prototype.$cc_url = process.env.VUE_APP_CC_URL_DEV;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL_DEV;
  Vue.prototype.$mialta_url = process.env.VUE_APP_MIALTA_URL_DEV;
  Vue.prototype.$mispedidos_url = process.env.VUE_APP_MISPEDIDOS_URL_DEV;
  Vue.prototype.$dev = true;
} else {
  Vue.prototype.$localurl = process.env.VUE_APP_URL_LOCAL;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL_LOCAL;
  Vue.prototype.$cyo_url = process.env.VUE_APP_CYO_URL_LOCAL;
  Vue.prototype.$cc_url = process.env.VUE_APP_CC_URL_LOCAL;
  Vue.prototype.$dev = true;
}

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue(); // Global event bus

// axios
const axios = new AxiosClient().createAxios();
Vue.prototype.$axios = axios;

// authService
const authService = new AuthService(axios);
Vue.prototype.$authService = authService;

// Registro global de la directiva click-outside
Vue.directive('click-outside', clickOutside); 
Vue.directive('infocus', infocus);

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

window.addEventListener('unauthorized', () => {
  if(app.$router.currentRoute.meta.requiresAuth || (!app.$router.currentRoute.meta.title && !app.$router.currentRoute.name)){
    // Token expirado o inválido
    clearUserDataAndRedirect();
  }
});
window.addEventListener('logout', clearUserDataAndRedirect);

let isRedirecting = false;
function clearUserDataAndRedirect() {
  if(isRedirecting){
    return
  }
  isRedirecting = true;
  // Realizar una solicitud al backend para eliminar la cookie
  axios.post('/api/auth/logout').finally(() => {
    // Continuar con el borrado local y redirección
    localStorage.removeItem('token');
    localStorage.removeItem('originalToken');
    localStorage.removeItem("token-landing");
    app.$store.commit("setReemplazante", null);
    localStorage.removeItem("reemplazo-id");
    localStorage.removeItem("clientesVinculados");
    app.$usuario = {
      rol: { rol: "" }
    };
    router.push('/login');
    isRedirecting = false;
  });
}