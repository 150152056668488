import { render, staticRenderFns } from "./TextDisplay.vue?vue&type=template&id=7c577d41&scoped=true"
import script from "./TextDisplay.vue?vue&type=script&lang=js"
export * from "./TextDisplay.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/form/text.scss?vue&type=style&index=0&id=7c577d41&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c577d41",
  null
  
)

export default component.exports