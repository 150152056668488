<template>
  <div class="header-container">
    <div class="top-bar">
      <div class="top-bar__left">
        <div class="header-mobile landing-top-menu">
          <img
            src="../assets/img/icons/menu.png"
            @click="mostrarMiAltaMenu = true"
          />
        </div>
        <img
          v-if="$usuario && $usuario.rolClientes != null"
          class="icon_default header-desktop"
          src="../assets/img/icons/profile_white.png"
          alt=""
        />
        <a
          class="header-desktop"
          v-if="$usuario && $usuario.rolClientes != null"
          @click="goTo('mialta')"
          >Mi Alta</a
        >
        <div
          v-if="$usuario && $usuario.rolClientes != null"
          class="vl header-desktop"
        />
        <img
          class="icon_default header-desktop"
          src="../assets/img/icons/folder_white.png"
          alt=""
        />
        <a class="header-desktop" @click="goTo('documentos')">Mis Documentos</a>
      </div>

      <div class="landing-top-hola header-desktop">
        <div>
          <p class="landing-top-hola-nombre">
            <b>{{ getNombre() }}</b>
          </p>
          <p class="landing-top-logout header__email">
            {{ $usuario.email }}
          </p>
        </div>
        <div
          class="landing-top-user-img-container "
          @click="mostrarLogoutMenu = true"
        >
          <img
            v-if="$usuario.imagen == null"
            class="landing-top-user-img"
            src="../assets/img/default.png"
          />
          <img
            class="landing-top-user-img"
            v-else
            :src="$localurl + '/' + $usuario.imagen"
          />
          <img
            v-if="getReemplazoId() && getReemplazanteImagen()"
            class="landing-top-user-img landing-top-reemplazo-img"
            :src="$localurl + '/' + getReemplazanteImagen()"
          />
          <img
            v-else-if="getReemplazoId()"
            class="landing-top-user-img landing-top-reemplazo-img"
            src="../assets/img/default.png"
          />
          <img
            class="landing-top-user-menu"
            src="../assets/img/flechas/triangle_bottom.png"
          />
        </div>
      </div>
      <div v-show="mostrarLogoutMenu" class="landing-top-logout-menu">
        <div
          class="landing-top-logout-menu-mask"
          @click="mostrarLogoutMenu = false"
        ></div>
        <div class="landing-top-logout-menu-popup">
          <label
            v-if="
              $usuario.rolClientes && $usuario.rolClientes.rol != 'Distribuidor'
            "
            @click="irAMiCuenta()"
            >Mi cuenta</label
          >
          <label
            v-if="
              $usuario.rolClientes &&
                $usuario.rolClientes.rol != 'Distribuidor' &&
                tieneReemplazos()
            "
            @click="irASeleccionarCuenta()"
            >Seleccionar cuenta</label
          >
          <label class="landing-top-logout-menu-popup-logout" @click="logout()"
            >Cerrar sesión</label
          >
        </div>
      </div>
      <!-- MOBILE >>> -->
      <div
        class="landing-top-user-img-container header-mobile"
        @click="mostrarLogoutMenu = true"
      >
        <img
          v-if="mostrarLogoutMenu"
          class="landing-top-user-menu arrow-icon header-mobile"
          src="../assets/img/flechas/chevron-up.svg"
        />
        <img
          v-if="!mostrarLogoutMenu"
          class="landing-top-user-menu arrow-icon header-mobile"
          src="../assets/img/flechas/chevron-down.svg"
        />
        <img
          v-if="$usuario.imagen == null"
          class="landing-top-user-img header-mobile"
          src="../assets/img/default.png"
        />
        <img
          class="landing-top-user-img header-mobile"
          v-else
          :src="$localurl + '/' + $usuario.imagen"
        />
        <img
          v-if="getReemplazoId() && getReemplazanteImagen()"
          class="landing-top-user-img landing-top-reemplazo-img header-mobile"
          :src="$localurl + '/' + getReemplazanteImagen()"
        />
        <img
          v-else-if="getReemplazoId()"
          class="landing-top-user-img landing-top-reemplazo-img header-mobile"
          src="../assets/img/default.png"
        />
      </div>
      <div v-show="mostrarMiAltaMenu" class="landing-top-logout-menu">
        <div
          class="landing-top-logout-menu-mask"
          @click.stop="mostrarMiAltaMenu = false"
        ></div>
        <div class="landing-top-mialta-menu-popup">
          <label v-if="$usuario.rolClientes != null" @click="goTo('mialta')"
            >Mi Alta</label
          >
          <label @click="goTo('documentos')">Mis Documentos</label>
        </div>
      </div>
    </div>

    <div class="header-logo">
      <img src="../assets/img/logo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
import helpers from "@/mixins/helpers";
import toolsMixin from "@/mixins/toolsMixin";

export default {
  mixins: [helpers, toolsMixin],
  data() {
    return {
      mostrarMiAltaMenu: false,
      mostrarLogoutMenu: false
    };
  },
  mounted() {
    this.$eventHub.$on("update", () => this.$forceUpdate());
  },
  methods: {
    logout() {
      this.$authService.logout();
    },
    redirectToHome() {
      this.$router.push("/");
    },
    getReemplazoId() {
      return localStorage.getItem("reemplazo-id");
    },
    irASeleccionarCuenta() {
      this.$router.push("/seleccionarCuenta");
    },
    irAMiCuenta() {
      localStorage.setItem("mialta-goTo-micuenta", true);
      this.goTo("mialta");
    },
    goTo(module) {
      this.goToModule(module);
    },
    getReemplazanteImagen() {
      const reemplazante = this.$store.getters.reemplazante;
      return reemplazante != null ? reemplazante.imagen : null;
    },
    tieneReemplazos() {
      if (
        this.$store.getters.reemplazos != null &&
        this.$store.getters.reemplazos.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    getNombre() {
      if (
        this.$usuario.nombre != null &&
        this.$usuario.email.split("@")[1].split(".")[0] == "basf"
      ) {
        return this.$usuario.nombre.split(" ")[0];
      } else if (this.$usuario.nombre != null) {
        return this.$usuario.nombre;
      } else {
        return "";
      }
    }
  },

  computed: {
    imageSrc: function() {
      return this.$usuario != null &&
        this.$usuario.imagen != null &&
        this.$usuario.imagen.length < 100
        ? this.$usuario.imagen
        : "";
    }
  }
};
</script>
<style src="@/assets/css/components/header.css"></style>
