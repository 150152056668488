<template>
  <Select :placeholder="usersCount ? `${usersCount} Usuarios` : null">
    <div class="container">
      <div>
        <h4>Usuarios seleccionados</h4>
        <p class="text-blue">{{ usersCount }} Usuarios</p>
      </div>
      <div>
        <h3>Herramienta</h3>
        <LabelCheckbox
          v-for="tool in tools.initial"
          :key="tool.app"
          v-model="tools.selected[tool.app]"
          @input="updateSelectedTools(tool.app, $event)"
          :text="tool.description"
        />
      </div>
      <div>
        <h3>Tipo de usuario</h3>
        <LabelCheckbox
          v-for="role in roles.initial"
          :key="role.role"
          :value="roles.selected[role.role]"
          @input="updateSelectedRoles(role.role, $event)"
          :text="role.description"
        />
      </div>
    </div>
  </Select>
</template>

<script>
import Select from "../form/Select.vue";
import LabelCheckbox from "@/components/form/LabelCheckbox.vue";

export default {
  components: {
    Select,
    LabelCheckbox
  },
  async mounted() {
    // Verifica si ya existe data en 'initial', si no, realiza las llamadas
    if (!this.tools.initial.length) {
      await this.fetchTools();
    }
    if (!this.roles.initial.length) {
      await this.fetchRoles();
    }

    // Inicializa los valores de selected con lo que ya haya en la store
    this.initializeSelectedTools();
    this.initializeSelectedRoles();
  },
  computed: {
    tools() {
      return this.$store.state.tools;
    },
    roles() {
      return this.$store.state.roles;
    },
    usersCount() {
      return this.$store.getters.getUsersCount;
    }
  },
  methods: {
    // Fetch tools from the store's actions
    async fetchTools() {
      await this.$store.dispatch("fetchTools", this.$axios, this.$localurl); // Pasa axios aquí
    },
    // Fetch roles from the store's actions
    async fetchRoles() {
      await this.$store.dispatch("fetchRoles", this.$axios, this.$localurl); // Pasa axios aquí
    },
    initializeSelectedTools() {
      // Inicializa los valores seleccionados si no están inicializados
      if (!Object.keys(this.tools.selected).length) {
        this.$store.dispatch("initializeSelectedTools");
      }
    },
    initializeSelectedRoles() {
      // Inicializa los valores seleccionados si no están inicializados
      if (!Object.keys(this.roles.selected).length) {
        this.$store.dispatch("initializeSelectedRoles");
      }
    },
    updateSelectedTools(app, isSelected) {
      this.$store.dispatch("updateSelectedTools", {
        app,
        isSelected,
        axiosProp: this.$axios
      });
      // Actualiza el estado localmente para asegurar reactividad
      this.$set(this.tools.selected, app, isSelected);
    },
    updateSelectedRoles(role, isSelected) {
      // Actualiza el estado seleccionado en roles.selected
      this.$store.dispatch("updateSelectedRoles", {
        role,
        isSelected,
        axiosProp: this.$axios
      });
      this.$set(this.roles.selected, role, isSelected);
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
h3 {
  font-weight: bold;
}
h4 {
  margin-bottom: 2px;
}
.text-blue {
  color: #48a0d4;
}
</style>
