import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/impersonate/:username',
    name: 'impersonate',
    beforeEnter: (to, from, next) => {
      const username = to.params.username;

      console.log(Vue.prototype.$localurl + `/impersonate?username=${username}`);
      
      Vue.prototype.$axios.get(Vue.prototype.$localurl + `/impersonate?username=${username}`)
        .then(response => {
          const newToken = response.data.token;
          if(newToken){
            localStorage.setItem('originalToken', localStorage.getItem('token'));
            localStorage.setItem('token', newToken);
            next('/');
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        })
        .catch(error => {
          console.error('Error impersonating user:', error);
        });
    },
  },
  {
    path: '/switchBack',
    name: 'switchBack',
    beforeEnter: (to, from, next) => {
      const originalToken = localStorage.getItem('originalToken');
      if(originalToken){
        localStorage.setItem('token', originalToken);
        localStorage.removeItem('originalToken');
      }
      next('/');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
  {
    path: "/login",
    name: "login",
    meta: { 
      title: "BASF Mi Cuenta - Login",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/",
    name: "landing",
    meta: { 
      title: "BASF Mi Cuenta",
      requiresAuth: true
    },
    component: () => import("../views/Landing.vue")
  },
  {
    path: "/permisos",
    name: "permisos",
    meta: { 
      title: "BASF Mi Cuenta - Permisos",
      requiresAuth: true,
      hasAnyRole: ["Performance"]
    },
    component: () => import("../views/Permisos.vue")
  },
  {
    path: "/password/:url",
    name: "password",
    meta: { title: "BASF Mi Cuenta - Password" },
    component: () =>
      import(/* webpackChunkName: "password" */ "../views/Password.vue")
  },
  {
    path: "/excel",
    name: "excel",
    meta: { 
      title: "BASF Mi Cuenta - Excel",
      requiresAuth: true,
      hasAnyRole: ["ADMIN"]
    },
    component: () => import("../views/Excel.vue")
  },
  {
    path: "/metricas",
    name: "metricas",
    meta: { 
      title: "BASF Mi Cuenta - Métricas",
      requiresAuth: true,
      hasAnyRole: ["ADMIN", "Performance"]
    },
    component: () => import("../views/Metricas.vue")
  },
  {
    path: "/seleccionarCuenta",
    name: "seleccionarCuenta",
    meta: { 
      title: "BASF Mi Cuenta - Seleccionar Cuenta",
      requiresAuth: true
    },
    component: () => import("../views/SeleccionarCuenta.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (!requiresAuth) {
    next();
  }

  const user = await Vue.prototype.$authService.getCurrentUser();
  const hasRequiredRole = user && (!to.meta.hasAnyRole || to.meta.hasAnyRole.includes(user.role));
  if (hasRequiredRole) {
    next();
  }else{
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('unauthorized'));
    }, 500);
  }
});

export default router;
