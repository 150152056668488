<template>
  <div>
    <DropdownV2 @toggle="handleToggle">
      <template #button>
        <button class="dropdown-button">
          {{ placeholder || "Selecciona" }}
          <span class="material-symbols-outlined">
            {{ isDropdownOpen ? "arrow_drop_up" : "arrow_drop_down" }}
          </span>
        </button>
      </template>
      <template #content>
        <slot></slot>
        <!-- Recibe contenido como hijo -->
      </template>
    </DropdownV2>
  </div>
</template>

<script>
import DropdownV2 from "./DropdownV2.vue";

export default {
  components: { DropdownV2 },
  data() {
    return {
      isDropdownOpen: false // Controla la dirección de la flecha
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "Selecciona"
    }
  },
  methods: {
    handleToggle(isOpen) {
      console.log("state Select component", this.$store.state.tools.selected);
      this.isDropdownOpen = isOpen; // Actualiza el estado de la flecha
    }
  }
};
</script>

<style scoped>
.dropdown-button {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: none;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
}

.dropdown-button:hover {
  border: 1px solid #0056b3;
}

.icon {
  margin-left: 10px;
  transition: transform 0.2s;
}
.icon-up {
  transform: rotate(180deg);
}
.icon-down {
  transform: rotate(0deg);
}
</style>
