<template>
  <div>
    <input
      type="checkbox"
      :name="text"
      :id="text"
      @change="statusCheck"
      :value="value"
      :checked="isChecked"
    />
    <label :for="text"> {{ text }} </label>
  </div>
</template>

<script>
export default {
  name: "label-check",
  props: {
    text: { type: String, required: true },
    value: { type: Boolean, default: false }
  },
  computed: {
    isChecked() {
      return this.value;
    }
  },
  methods: {
    statusCheck(event) {
      const isChecked = event.target.checked;
      this.$emit("input", isChecked); // Para v-model
      this.$emit("change", isChecked); // Para manejar el evento explícitamente
    }
  }
};
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
label {
  font-family: "Roboto", sans-serif;
  color: #000;
}
input {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
@media screen and (max-width: 1366px) {
  input {
    width: 18px;
    height: 18px;
  }
}
</style>
