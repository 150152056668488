import * as jwt from 'jwt-decode';

class AuthService {
  constructor(axios) {
    this.axios = axios;
  }

  async login(email, password) {
    const response = await this.axios.post("/api/auth/login", {
      email,
      password
    });
    localStorage.setItem('token', response.data);
    const user = await this.getCurrentUser();
    localStorage.setItem("user-id", user.id);
  }

  logout() {
    window.dispatchEvent(new CustomEvent('logout'));
  }

  async getCurrentUser() {
    const token = localStorage.getItem('token');
    if(!token) {
      return null;
    }
    if (token && this.isTokenExpiring(token)) {
      const newToken = await this.refreshToken();
      localStorage.setItem('token', newToken);
      return jwt.jwtDecode(newToken);
    }
    return jwt.jwtDecode(token);
  }

  isTokenExpiring(token) {
    try {
      const decoded = jwt.jwtDecode(token);
      const exp = decoded.exp * 1000; // JWT exp en segundos, por lo que multiplicamos por 1000
      return (exp - Date.now()) < (10 * 60 * 1000); // Considerar expiring si quedan menos de 10 minutos
    } catch (error) {
      return false;
    }
  }

  refreshToken() {
    return this.axios.post('/api/auth/refresh-token')
      .then(response => response.data);
  }
}

export default AuthService;