import colorMixin from "./colorMixin";

export default {
  // importo los otros mixins
  mixins: [colorMixin],
  computed: {
    userIsPerformance() {
      return (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance")
      );
    }
  },
  methods: {}
};
